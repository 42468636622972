*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Fraunces, serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media (width >= 1260px) {
  .container {
    max-width: 1260px;
  }
}

@media (width >= 1680px) {
  .container {
    max-width: 1680px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-64 {
  left: -16rem;
}

.-top-12 {
  top: -3rem;
}

.-top-52 {
  top: -13rem;
}

.-top-\[450px\] {
  top: -450px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-3 {
  left: .75rem;
}

.right-5 {
  right: 1.25rem;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-8 {
  top: 2rem;
}

.top-9 {
  top: 2.25rem;
}

.z-20 {
  z-index: 20;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-\[calc\(100vh-14\%\)\] {
  height: calc(100vh - 14%);
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-72 {
  min-height: 18rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-96 {
  width: 24rem;
}

.w-\[26rem\] {
  width: 26rem;
}

.w-\[500px\] {
  width: 500px;
}

.w-full {
  width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.bg-body {
  --tw-bg-opacity: 1;
  background-color: rgb(239 230 229 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(150 82 153 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  object-fit: cover;
}

.object-right {
  object-position: right;
}

.p-3 {
  padding: .75rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-\[\'DM_Serif_Display\'\] {
  font-family: DM Serif Display;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(150 82 153 / var(--tw-text-opacity));
}

.text-violet {
  --tw-text-opacity: 1;
  color: rgb(90 46 126 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\!opacity-10 {
  opacity: .1 !important;
}

.opacity-70 {
  opacity: .7;
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

html {
  scroll-behavior: smooth;
}

@media (width >= 768px) {
  html {
    overflow-anchor: none;
  }
}

html h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}

@media (width >= 1024px) {
  html h2 {
    font-size: 4.5rem;
  }
}

html p {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 1024px) {
  html p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

header {
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

header .c-main-menu {
  display: none;
}

@media (width >= 768px) {
  header .c-main-menu {
    display: block;
  }
}

header .c-main-menu li a {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

header .c-main-menu li a:hover {
  --tw-text-opacity: 1;
  color: rgb(150 82 153 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

header .c-main-menu li span {
  --tw-text-opacity: 1;
  color: rgb(150 82 153 / var(--tw-text-opacity));
  font-size: .75rem;
  line-height: 1rem;
}

.divider {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  width: 100%;
  height: 1px;
  position: relative;
}

.divider:before {
  --tw-bg-opacity: 1;
  background-color: rgb(239 230 229 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(150 82 153 / var(--tw-text-opacity));
  --tw-content: "♥";
  content: var(--tw-content);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  top: -1.25rem;
  left: 0;
  right: 0;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:right-0:before {
  content: var(--tw-content);
  right: 0;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:z-10:before {
  content: var(--tw-content);
  z-index: 10;
}

.before\:mx-auto:before {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}

.before\:h-80:before {
  content: var(--tw-content);
  height: 20rem;
}

.before\:w-80:before {
  content: var(--tw-content);
  width: 20rem;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-primary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(150 82 153 / var(--tw-bg-opacity));
}

.before\:bg-white:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.before\:bg-opacity-10:before {
  content: var(--tw-content);
  --tw-bg-opacity: .1;
}

.before\:bg-opacity-50:before {
  content: var(--tw-content);
  --tw-bg-opacity: .5;
}

.hover\:bg-violet:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(90 46 126 / var(--tw-bg-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(150 82 153 / var(--tw-text-opacity));
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 1024px) {
  .lg\:-top-36 {
    top: -9rem;
  }

  .lg\:-top-64 {
    top: -16rem;
  }

  .lg\:bottom-3 {
    bottom: .75rem;
  }

  .lg\:right-20 {
    right: 5rem;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mt-44 {
    margin-top: 11rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-52 {
    width: 13rem;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-52 {
    padding-bottom: 13rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:text-start {
    text-align: start;
  }

  .lg\:text-144 {
    font-size: 8rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-\[40px\] {
    font-size: 40px;
  }

  .lg\:before\:h-\[400px\]:before {
    content: var(--tw-content);
    height: 400px;
  }

  .lg\:before\:w-\[400px\]:before {
    content: var(--tw-content);
    width: 400px;
  }
}
/*# sourceMappingURL=en.22e15577.css.map */
