@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth md:[overflow-anchor:none];

  h2 {
    @apply text-5xl lg:text-72 font-bold;
  }

  p {
    @apply text-lg lg:text-xl;
  }
}

header {
  @apply absolute left-0 right-0 z-50 top-0;

  .c-main-menu {
    @apply hidden md:block;

    li {
      a {
        @apply font-medium text-2xl hover:underline hover:text-primary transition-all;
      }

      span {
        @apply text-primary text-xs;
      }
    }
  }
}

.divider {
  @apply w-full h-px bg-black relative before:absolute before:left-0 before:right-0 before:mx-auto before:-top-5 before:rounded-full before:text-center before:text-primary before:w-10 before:h-10 before:flex before:justify-center before:items-center before:bg-body before:content-['♥'];
}
